import React, { useEffect } from "react"
import Slider, { CustomArrowProps } from "react-slick"
import clsx from "clsx"

import { ClientTrackingEventName, IAdvisor } from "../../../types"
import ExpertEntry from "../../MyQuestico/Dashboard/Expert/ExpertEntry"
import ArrowIcon from "../../_common/QuesticoIcon/ArrowIcon"
import { useMediaQuery, useTheme } from "../../_common"
import { StaticListProps } from "../StaticList/types"
import useExpertsListingTracking from "../../../hooks/tracking/useExpertsListingTracking"
import classes from "./HorizontalExpertsList.module.scss"

interface IDashboardListProps
  extends Pick<StaticListProps, "getClientTrackingProperties"> {
  content: Array<IAdvisor>
  emptyElement: React.ReactElement
  isLoading: boolean
  loadingComponent: React.ReactElement
  listingName: string
  expertRootClassName?: string
  avatarClassName?: string
  onlineStatusClassName?: string
  expertNameClassName?: string
  listClassName?: string
  isMinimalExpertView?: boolean
}

interface IArrowProps extends CustomArrowProps {
  rootClassName: string
}

export const SamplePrevArrow: React.FC<IArrowProps> = (props) => {
  const { onClick, rootClassName } = props
  return (
    <button type="button" onClick={onClick} className={rootClassName}>
      <ArrowIcon height={14} width={18} />
    </button>
  )
}

const HorizontalExpertsList: React.FC<IDashboardListProps> = ({
  content = [],
  emptyElement,
  isLoading,
  loadingComponent,
  getClientTrackingProperties,
  listingName,
  expertRootClassName,
  avatarClassName,
  onlineStatusClassName,
  expertNameClassName,
  listClassName,
  isMinimalExpertView,
}) => {
  const { trackEvent: trackExpertsListingEvent } = useExpertsListingTracking()
  const theme = useTheme()
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"))
  const isMinimal = isMinimalExpertView || !isDesktop

  const list = content.map((expert) => (
    <ExpertEntry
      key={expert.listing_number}
      expert={expert}
      minimal={isMinimal}
      listingName={listingName}
      expertRootClassName={expertRootClassName}
      avatarClassName={avatarClassName}
      onlineStatusClassName={onlineStatusClassName}
      expertNameClassName={expertNameClassName}
    />
  ))

  const contentIsEmpty = content.length === 0

  useEffect(() => {
    if (contentIsEmpty) return

    const customClientTrackingProperties =
      getClientTrackingProperties && getClientTrackingProperties(content)

    if (customClientTrackingProperties !== false) {
      trackExpertsListingEvent({
        content,
        eventName: ClientTrackingEventName.PRODUCT_LIST_VIEW,
        customClientTrackingProperties,
      })
    }
  }, [contentIsEmpty])

  const listClassNameFinal = clsx(classes.list, listClassName)

  if (isLoading) {
    return (
      <div className={listClassNameFinal}>
        {Array.from(Array(3).keys()).map((i) => (
          <React.Fragment key={i}>{loadingComponent}</React.Fragment>
        ))}
      </div>
    )
  }

  if (!content.length) {
    return emptyElement
  }

  if (!isDesktop) {
    return <div className={listClassNameFinal}>{list}</div>
  }

  const settings = {
    arrows: true,
    slidesToScroll: 1,
    variableWidth: true,
    infinite: true,
    nextArrow: (
      <SamplePrevArrow
        rootClassName={clsx(classes.button, classes.nextButton)}
      />
    ),
    prevArrow: <SamplePrevArrow rootClassName={classes.button} />,
    responsive: [
      {
        breakpoint: 599,
        settings: {
          arrows: false,
          centerMode: true,
          slidesToShow: 1,
          slidesToScroll: 1,
          swipeToSlide: true,
        },
      },
      {
        breakpoint: 360,
        settings: {
          autoplay: true,
          autoplaySpeed: 5000,
          arrows: false,
          slidesToShow: 1,
          slidesToScroll: 1,
          swipeToSlide: true,
        },
      },
    ],
  }

  return (
    <>
      {content.length > 3 ? (
        <>
          {/* @ts-ignore */}
          <Slider
            className={clsx(classes.slider, {
              [classes.minimalExpertView]: isMinimal,
            })}
            {...settings}
          >
            {list}
          </Slider>
        </>
      ) : (
        <div className={listClassNameFinal}>{list}</div>
      )}
    </>
  )
}

export default HorizontalExpertsList
