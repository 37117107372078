import React, { useCallback } from "react"
import clsx from "clsx"

import ProductPrice from "../../../Experts/ProductPrice"
import { IAdvisor } from "../../../../types"
import { AdviqoLink } from "../../../_common"
import ProductsList from "../../../Experts/Products/ProductsList"
import Avatar from "../../../Experts/Avatar"
import { buildProfileUrl } from "../../../../utils/slugUtils"
import { getMouseClick, trackProductClick } from "../../../Experts/utils"
import { useTracking } from "../../../../hooks/tracking"
import { useRuntimeConfig } from "../../../../hooks/runtimeConfig"
import useMyFavorites from "../../../../hooks/myFavorites/useMyFavorites"
import FavouriteButtonContainer from "../../../Experts/ExpertProfile/ProfileHero/Actions/FavouriteButton"
import classes from "./ExpertEntry.module.scss"

interface IExpertProps {
  expert: IAdvisor
  minimal?: boolean
  expertRootClassName?: string
  avatarClassName?: string
  onlineStatusClassName?: string
  expertNameClassName?: string
  listingName?: string
}

const ExpertEntry: React.FC<IExpertProps> = ({
  expert,
  minimal = false,
  expertRootClassName,
  avatarClassName,
  onlineStatusClassName,
  expertNameClassName,
  listingName,
}) => {
  const {
    id,
    listing_number: listingNumber,
    avatar,
    name,
    products,
    status,
    promotion,
  } = expert

  const { checkIsFavourite } = useMyFavorites()
  const isFavorite = checkIsFavourite(id)
  const { getRuntimeConfigByKey } = useRuntimeConfig()

  const { trackEvent, setProperties } = useTracking()

  const clickHandler = useCallback(
    (e: React.MouseEvent) => {
      trackProductClick(
        getMouseClick(e),
        expert,
        listingName,
        trackEvent,
        getRuntimeConfigByKey("MANDATOR_NUMBER")
      )
      setProperties({
        listingName,
      })
    },
    [expert, getRuntimeConfigByKey, listingName, setProperties, trackEvent]
  )

  const link = buildProfileUrl(name, listingNumber)

  return (
    <div className={clsx(classes.root, expertRootClassName)}>
      <div
        className={clsx(classes.content, {
          [classes.minimalContent]: minimal,
        })}
      >
        <AdviqoLink underline="none" to={link} onClick={clickHandler}>
          <Avatar
            className={clsx(classes.avatar, avatarClassName)}
            photo={avatar}
            displayName={name}
            onlineStatus={status}
            onlineStatusClassName={clsx(
              classes.onlineStatus,
              onlineStatusClassName
            )}
          />
        </AdviqoLink>
        <div
          className={clsx(classes.expertData, {
            [classes.expertMinimalData]: minimal,
          })}
        >
          <AdviqoLink
            className={clsx(classes.expertName, expertNameClassName)}
            underline="none"
            to={link}
            title={name}
            onClick={clickHandler}
          >
            {name}
          </AdviqoLink>
          {!minimal && (
            <div className={classes.productSection}>
              <ProductPrice
                rootClassName={classes.promotion}
                promotion={expert.promotion}
                products={expert.products}
                promotionVariation="minimal-dense"
                forcedHidePrice
                promoBreakLine
              />
              <FavouriteButtonContainer
                listingNumber={listingNumber}
                isFavorite={isFavorite}
                id={id}
                testId="add-remove-expert"
                buttonClassName={classes.favoriteButton}
              />
            </div>
          )}
        </div>
      </div>
      {!minimal && (
        <div className={classes.footer}>
          <ProductsList
            promotion={promotion}
            products={products}
            listingNo={listingNumber}
            photo={avatar}
            displayName={name}
            withLabel={false}
            withPrice
            advisorStatus={status}
            initSrc={listingName}
          />
        </div>
      )}
    </div>
  )
}

export default ExpertEntry
